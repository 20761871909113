body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-wrapper {
  padding: 0px 20px 0px 40px;
}

.controls {
  display: flex;
  padding: 20px 20px 40px 40px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.text-wrapper {
  width: 200px;
}

.select-wrapper {
  flex-grow: 1;
  z-index: 4;
}

.slider-wrapper {
  flex-grow: 1;
  padding: 0 50px;
}

.range-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  text-align: center;
}

.description {
  font-size: 14px;
  padding: 40px;
  line-height: 1.5em;
}

.diffs-wrapper {
  padding: 0 40px;
}

.diff-gauges-wrapper {
  display: flex;
  justify-content: space-around;
}

.diff-header {
  margin-bottom: 50px;
}

.gauge-title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  font-size: 26px;
}

.gauge-values {
  font-size: 12px;
  text-align: center;
}

.gauge-wrapper {
  flex: 1 1 0px;
  text-align: center;
  max-width: 400px;
  padding: 0 10px;
  box-sizing: border-box;
}

@media (max-width: 700px) {
  .diff-gauges-wrapper {
    flex-wrap: wrap;
  }

  .gauge-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 550px) {
  .header-wrapper h1 {
    font-size: 24px;
  }

  .controls {
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .controls > div {
    width: 100%;
  }

  .header-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }

  .text-wrapper {
  }

  .select-wrapper {
    padding-top: 20px;
    padding-bottom: 0px;
  }

  .slider-wrapper {
    padding-top: 20px;
  }

  .range-wrapper {
    padding-top: 30px;
  }

  .description {
    padding: 20px;
  }

  .diffs-wrapper {
    padding: 0 20px;
  }
}
